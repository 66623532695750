<template>
  <div class="LocatieInformatie__Attachments mb-2">
    <strong class="font-weight-bold">Bijlagen</strong>
    <template v-if="! isLoading">
      <Lightbox
        v-if="images.length > 0"
        :gallery="images"
      >
        <template #silentbox-item="{ item }">
          <span class="font-light">{{ item.description }}</span>
          <div class="ratio ratio-16x9">
            <div class="inner">
              <img
                :src="item.src"
                :alt="item.description"
              >
            </div>
          </div>
        </template>
      </Lightbox>
      <p
        v-else
        class="mb-0"
      >
        Geen bijlagen beschikbaar
      </p>
    </template>
    <p
      v-else
      class="mb-0"
    >
      Loading...
      <b-spinner
        small
        variant="primary"
        label="Spinning"
      />
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkStatus, returnJson } from '@/helpers/api'
//import { Bugfender } from '@bugfender/sdk'
import { attachmentIDToDescription, VALID_ATTACHMENT_IDS } from '@/../shared/valueholders/attachments'
import Lightbox from '@/components/common/lightbox/Lightbox.vue'

export default {
  name: 'ChargingpointAttachments',
  components: { Lightbox },
  props: {
    chargingpoint: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      images: [],
    }
  },
  computed: {
    ...mapGetters('config', ['participationConfig']),
  },
  async created() {
    await this.fetchAttachments()
  },
  methods: {
    async fetchAttachments() {
      this.isLoading = true

      this.images = []

      for (const id of VALID_ATTACHMENT_IDS) {
        try {
          const apiUrl = process.env.VUE_APP_AWS_APIV3_BASE_URL
          const response = await fetch(`${apiUrl}chargingpoint/${this.chargingpoint.uuid}/files/${id}?output=url`, {
            headers: {
              'x-api-key': process.env.VUE_APP_AWS_API_KEY,
            },

          })
            .then(await checkStatus)
            .then(returnJson)

          if (!response.url) {
            return
          }

          const filename = response.url.split('?')[0].split('/').pop()
          const fileType = filename.split('.')[0].split('-').pop()

          await fetch(response.url)
            .then(await checkStatus)
            .then(result => result.blob())
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
              this.images.push({
                src: url,
                description: attachmentIDToDescription({ id: fileType }),
                filename,
              })
            }).catch(err => console.log(err))

          this.isLoading = false
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log('error in file download')
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import "./src/assets/sass/config";

.LocatieInformatie {
  &__Attachments {
    padding-top: .5rem;
    border-top: 1px solid #CCCCCC !important;

    &__Image {
      width: 49.5%;
      position: relative;

      &__Inline {
        right: 7px;
      }

      .ratio {
        overflow: hidden;
      }

      img {
        cursor: pointer;
        width: 100%;
      }
    }
  }
}
</style>
